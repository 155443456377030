@font-face {
  font-family: "Digital 7 Regular";
  src: url("assets/digital-7.regular.ttf");
  font-style: normal;
  font-weight: normal;
}
body {
  font-family: "Verdana", sans-serif;
  color: #403233;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.flex-centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.text-danger {
  color: red;
}