.about {
  width: 300px;
  margin: 0 auto;
}

p,
a {
  font-size: 1.1rem;
}

a {
  color: #f19a3e;
  text-decoration: none;
}

ul {
  padding-left: 20px;
}
