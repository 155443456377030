.pomodoro-clock {
  background-color: #b9ffb7;
  width: 450px;
  margin: 0 auto;
  padding: 30px 30px 40px 30px;
  display: flex;
  align-items: center;
}

.increment,
.decrement,
.clock-button {
  border: none;
  color: white;
  background-color: #403233;
  padding: 1rem;
}

.increment:hover,
.increment :focus,
.decrement:hover,
.decrement :focus,
.clock-button:hover,
.clock-button :focus {
  background-color: #5d484a;
}

.increment,
.decrement {
  border-radius: 50%;
  margin-left: 15px;
  margin-right: 15px;
}

.label,
.label-number {
  text-align: center;
}

.label-number {
  font-family: "Digital 7 Regular";
  font-size: 1.5rem;
}

.clock {
  text-align: center;
  background-color: azure;
  padding: 10px;
}

.clock-display {
  font-family: "Digital 7 Regular";
  font-size: 2rem;
  color: white;
  background-color: #403233;
  padding: 5px 0 10px 0;
}

.clock-start {
  display: flex;
}

.clock-reset .svg-inline--fa {
  display: block;
}

@media screen and (max-width: 520px) {
  .pomodoro-clock {
    width: auto;
    flex-direction: column;
  }

  .clock {
    margin: 20px 0 10px 0;
  }
}
